import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from "react-hook-form";

const RolesRights = (props) => {
	const resetData = props.resetData;
	const setAreDataSaved = props.setAreDataSaved;

	const { register } = useFormContext();

	const navigate = useNavigate();

	const canUserModifyRights = sessionStorage.getItem("modify_user_rights") === "true";

	return (
		<>
			<table style={{width: "100%"}}>
				<thead>
					<tr>
						<th style={{width: "50%", textAlign: "left"}}>Rights</th>
						<th>Employee</th>
						<th>Manager</th>
						<th>Administrator</th>
					</tr>
				</thead>
				<tbody>
					<tr className="settings-box__user-rights__subtitle">
						<td colSpan="4">Home page settings</td>
					</tr>
					<tr className="settings-box__user-rights__sub-content">
						<td>Insert a new booking</td>
						<td>
							<label>
								<input type="checkbox" value="employee"
									{...register("insert_bookings", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="executive"
									{...register("insert_bookings", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="admin"
									{...register("insert_bookings", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
					</tr>
					<tr className="settings-box__user-rights__sub-content">
						<td>Modify a booking</td>
						<td>
							<label>
								<input type="checkbox" value="employee"
									{...register("update_bookings", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="executive"
									{...register("update_bookings", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="admin"
									{...register("update_bookings", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
					</tr>
					<tr className="settings-box__user-rights__sub-content">
						<td>Delete a booking</td>
						<td>
							<label>
								<input type="checkbox" value="employee"
									{...register("delete_bookings", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="executive"
									{...register("delete_bookings", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="admin"
									{...register("delete_bookings", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
					</tr>
					<tr className="settings-box__user-rights__sub-content">
						<td>Update a booking state</td>
						<td>
							<label>
								<input type="checkbox" value="employee"
									{...register("update_state_bookings", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="executive"
									{...register("update_state_bookings", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="admin"
									{...register("update_state_bookings", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
					</tr>

					{/*	-------------- */}
					{/* CALLENDAR PAGE */}
					{/*	-------------- */}

					<tr className="settings-box__user-rights__subtitle">
						<td colSpan="4">Callendar page settings</td>
					</tr>

					{/*	----------------- */}
					{/* SEATING PLAN PAGE */}
					{/*	----------------- */}

					<tr className="settings-box__user-rights__subtitle">
						<td colSpan="4">Seating plan page settings</td>
					</tr>
					<tr className="settings-box__user-rights__sub-content">
						<td>Create a new POS floor</td>
						<td>
							<label>
								<input type="checkbox" value="employee"
									{...register("create_floor_pos", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="executive"
									{...register("create_floor_pos", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="admin"
									{...register("create_floor_pos", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
					</tr>
					<tr className="settings-box__user-rights__sub-content">
						<td>Rename POS floors</td>
						<td>
							<label>
								<input type="checkbox" value="employee"
									{...register("rename_floor_pos", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="executive"
									{...register("rename_floor_pos", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="admin"
									{...register("rename_floor_pos", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
					</tr>
					<tr className="settings-box__user-rights__sub-content">
						<td>Modify seating plan for each floor</td>
						<td>
							<label>
								<input type="checkbox" value="employee"
									{...register("modify_floor_pos", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="executive"
									{...register("modify_floor_pos", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="admin"
									{...register("modify_floor_pos", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
					</tr>
					<tr className="settings-box__user-rights__sub-content">
						<td>Delete a POS floor</td>
						<td>
							<label>
								<input type="checkbox" value="employee"
									{...register("delete_pos", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="executive"
									{...register("delete_pos", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="admin"
									{...register("delete_pos", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
					</tr>
					<tr className="settings-box__user-rights__sub-content">
						<td>Associate a booking to a table</td>
						<td>
							<label>
								<input type="checkbox" value="employee"
									{...register("associate_client_pos", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="executive"
									{...register("associate_client_pos", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="admin"
									{...register("associate_client_pos", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
					</tr>
					{/* TO DO : Which POS / floor you can modify */}

					{/*	------------- */}
					{/* SETTINGS PAGE */}
					{/*	------------- */}

					<tr className="settings-box__user-rights__subtitle">
						<td colSpan="4">Settings page settings</td>
					</tr>
					<tr className="settings-box__user-rights__sub-content">
						<td>Accede to settings page</td>
						<td>
							<label>
								<input type="checkbox" value="employee"
									{...register("settings_access", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="executive"
									{...register("settings_access", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="admin"
									{...register("settings_access", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
					</tr>
					<tr className="settings-box__user-rights__sub-content">
						<td>Modify POS settings</td>
						<td>
							<label>
								<input type="checkbox" value="employee"
									{...register("update_pos_settings", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="executive"
									{...register("update_pos_settings", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="admin"
									{...register("update_pos_settings", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
					</tr>
					<tr className="settings-box__user-rights__sub-content">
						<td>Accept/refuse booking</td>
						<td>
							<label>
								<input type="checkbox" value="employee"
									{...register("accept_booking", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="executive"
									{...register("accept_booking", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="admin"
									{...register("accept_booking", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
					</tr>
					<tr className="settings-box__user-rights__sub-content">
						<td>Modify user rights</td>
						<td>
							<label>
								<input type="checkbox" value="employee"
									{...register("modify_user_rights", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="executive"
									{...register("modify_user_rights", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="admin"
									{...register("modify_user_rights", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
					</tr>
					<tr className="settings-box__user-rights__sub-content">
						<td>See stastistics</td>
						<td>
							<label>
								<input type="checkbox" value="employee"
									{...register("access_statistics", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="executive"
									{...register("access_statistics", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
						<td>
							<label>
								<input type="checkbox" value="admin"
									{...register("access_statistics", {disabled: !canUserModifyRights})}
								/>
								<span className="checkbox-custom"></span>
							</label>
						</td>
					</tr>
				</tbody>
			</table>

			<div className="flex-row-between">
				<div className="flex-row settings-box__user-rights-inputs">
					<input type="submit" value="Modify" style={{cursor: "pointer"}} disabled={!canUserModifyRights}/>
					<input type="reset" value="Abort changes" onClick={(e) => resetData(e) & setAreDataSaved(true)} style={{cursor: "pointer"}}/>
				</div>
				<button
					style={{width: "fit-content", margin: "15px 10px"}}
					onClick={(e) => navigate(`/logup`)}
				>Add a new account</button>
			</div>
		</>
	)
}

export default RolesRights;