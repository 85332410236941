import React from 'react';

const Aside = () => {
	return (
		<>
		<div className="logo">
			<img src="pictures/logo.svg" alt=""/>
		</div>
		<p>No reservation selected...</p>
		</>
	)
}

export default Aside;
