import React from 'react';
import { useState, useEffect } from 'react';

import Axios from 'axios';

import { get_bookingsQuantity } from '../../requests/bookings/get_bookingsQuantity';
import { get_bookings } from '../../requests/bookings/get_bookings';

// import insertLog from '../logs/insertLog';

import Booking from './booking';

import { useToast } from '../../hooks/useToast';

const BookingsTable = (props) => {
	/*
	* Component useState constants
	*/
	const bookingSelected 	 = props.bookingSelected;
	const setBookingSelected = props.setBookingSelected;

	const [bookings, setBookings] = useState({});

	const [isLoading, setIsLoading] = useState(true);

	const [dataPage, setDataPage] = useState(20);
	const [dataQuantity, setDataQuantity] = useState(0);

	/*
	* Component props
	*/
	const searchInput  = props.searchInput;
	const isSearching = props.isSearching;
	
	const setValue 		= props.setValue;
	const setAsideType  = props.setAsideType;

	const reloadBookings = props.reloadBookings;
	const setReloadBookings = props.setReloadBookings;

	const toast = useToast();

	useEffect(() => {
		setIsLoading(true);

		get_bookingsQuantity()
		.then((res) => {
			setIsLoading(false);
			setDataQuantity(res.value);
		})
		.catch(res => toast(res.state, res.value));
	}, []);

	useEffect(() => {
		setIsLoading(true);

		get_bookings(true, false, dataPage, searchInput)
		.then((res) => {
			setIsLoading(false);
			setBookings(res.value)
			props.setIsSearching(false); // Stop searching state
		})
		.catch(res => toast(res.state, res.value));
	}, [dataPage, reloadBookings, isSearching]);

	const getBookingsLength = () => {
		var counter = 0;
		if (bookings === []) return 0;

		Object.values(bookings).map(elm => {
			counter += elm.length;
		})

		return counter;
	}

	return (
		<>
		{
			!isLoading && bookings !== [] ? (
				Object.entries(bookings).map(([day, rows]) => {
					let dayName = "Data researched";
				
					if (day === "yesterday") dayName = "Yesterday";
					if (day === "today") dayName = "Today";
					if (day === "tomorrow") dayName = "Tomorrow";
					if (day === "nextDays") dayName = "Next days";

					if (rows.length === 0) return <></>;

					return (
						<>
						<h1 style={{margin: "40px 0 -10px 0"}}>{dayName}</h1>
						{ 
							rows.map((object, index) => {
								return (
									<Booking
										bookingId = {object.id}
										firstName =	{object.first_name}
										lastName =  {object.last_name}
										place = 	{object.place}
										date = 		{object.booking_date}
										time = 		{object.time}
										coverts = 	{object.coverts}
										isAccepted = {object.is_accepted}
										intExt = 	{object.internal}
										contact = 	{object.contact}
										notes = 	{object.notes}
										state = 	{object.state}

										setValue = {setValue}
										setAsideType= {setAsideType}
										bookingSelected = {bookingSelected}
										setBookingSelected = {setBookingSelected}
										reloadBookings = {reloadBookings}
										setReloadBookings = {setReloadBookings}

										key={"bookingRow-" + object.id + "-" +index}
									/>
								);
							})
						}
						</>
					);
				})
			) : (
				<div className="grid-center loader">
					<div>
						<img src="pictures/loadingIcon.svg" alt="loader"/>
						<h4>Loading bookings...</h4>
					</div>
				</div>
			)
		}
		{
			!isLoading && dataQuantity <= dataPage && getBookingsLength() > 0 ? 
				<h2>There are no more bookings...</h2> : 
				(dataQuantity === 0 ? <h2>No bookings found...</h2> : <h2>There are no more bookings...</h2>)
		}
		{
			!isLoading && dataQuantity > dataPage ?
				<button id="showMoreBookings" onClick={() => setDataPage(dataPage + 20)} style={{width: "fit-content"}}>See more bookings</button> : ''
		}
		</>
	)
}
export default BookingsTable;
