import React from 'react';
import { useState, useEffect } from 'react';
import { useForm, FormProvider } from "react-hook-form";

import { useToast } from '../../hooks/useToast';

import { get_userFamilyRights } from '../../requests/settings/get_userFamilyRights';
import { put_userFamilyRights } from '../../requests/settings/put_userFamilyRights';

// import insertLog from '../logs/insertLog';

import RolesRights from './assets/RolesRights';
// import UsersRightsList from './assets/UsersRightsList';

const UsersRights = (props) => {
	const toast = useToast();

	const [isLoading, setIsLoading] = useState(true);
	const [timesReloaded, setTimesReloaded] = useState(0);

	const [tabSelected, setTabSelected] = useState("roles");

	const setAreDataSaved = props.setAreDataSaved;

	const formMethods = useForm();
	const { 
		handleSubmit, 
		setValue
		// setError, 
		// clearErrors 
	} = formMethods;

	useEffect(() => {
		setIsLoading(true);
		get_userFamilyRights()
		.then((res) => {
			setIsLoading(false);

			for (const [key, value] of Object.entries(res.value)) setValue(key, value);
		})
		.catch(res => toast(res.state, res.value));

	}, [timesReloaded]);

	const updateValues = (data) => {
		setIsLoading(true);

		put_userFamilyRights(data)
		.then(res => {
			toast(res.state, res.value);
			setTimesReloaded(timesReloaded + 1);
		})
		.catch(res => toast(res.state, res.value));

		setIsLoading(false);
		setAreDataSaved(true);
	}

	const resetData = (event) => {
		event.preventDefault();

		setTimesReloaded(timesReloaded + 1);
	}

	return (
		<>
		<div className="flex-row-around settings-box__header">
			<div 
				className="tabs" 
				onClick={() => setTabSelected("roles")} 
				style={{
					backgroundColor: tabSelected === "rights" ? "#E3E3E3" : '', 
					color: tabSelected === "rights" ? "#6F6F6F" : '',
					boxShadow: tabSelected === "roles" ? "2px 0 5px rgba(0, 0, 0, .1)" : '',
					zIndex: tabSelected === "roles" ? 10 : 1,
					cursor: tabSelected === "rights" ? "pointer" : "default"
				}}
			>
				<h4>Family users rights</h4>
			</div>
			<div 
				className="tabs" 
				onClick={() => setTabSelected("rights")} 
				style={{
					backgroundColor: tabSelected === "roles" ? "#E3E3E3" : '',
					color: tabSelected === "roles" ? "#6F6F6F" : '',
					boxShadow: tabSelected === "rights" ? "-2px 0 5px rgba(0, 0, 0, .1)" : '',
					zIndex: tabSelected === "rights" ? 10 : 1,
					cursor: tabSelected === "roles" ? "pointer" : "default"
				}}
			>
				<h4>Users rights</h4>
			</div>
		</div>
		<FormProvider {...formMethods}>
			<form 
				id="users-rights-form" 
				onClick={event => event.target.type === "checkbox" ? setAreDataSaved(false) : null}
				onSubmit={handleSubmit(updateValues)}
			>
				<div className="settings-box__content"
					style={{filter: isLoading ? 'blur(6px) opacity(.6)' : ''}}
				>
					{
						tabSelected === "roles" && 
						<RolesRights 
							resetData={resetData}
							updateValues={updateValues}
							setAreDataSaved={setAreDataSaved}
						/>
						// <UsersRightsList

						// />
					}
				</div>
			</form>
		</FormProvider>
		{ isLoading ?
			<div className="grid-center loader" style={{marginTop: "-365px", position: 'relative', zIndex: 1}}>
				<div>
					<img src="pictures/loadingIcon.svg" alt="loader"/>
					<h4>Loading settings...</h4>
				</div>
			</div> : ''
		}
		</>
	)
}

export default UsersRights;
