import Axios from 'axios';

const getRights = (id) => {
	return new Promise((resolve, reject) => {
		if (process.env.REACT_APP_DISPLAY_LOGS_3) console.log("[Get rights]: called.");

		Axios.get(`${process.env.REACT_APP_API_ADRESS}/api/users/rights`, {
			params: {
				id: id
			}
		})
		.then((res) => {
			console.log(res)
			// Rights
			sessionStorage.setItem("insert_bookings", res.data.value.insert_bookings === 1);
			sessionStorage.setItem("update_bookings", res.data.value.update_bookings === 1);
			sessionStorage.setItem("delete_bookings", res.data.value.delete_bookings === 1);
			sessionStorage.setItem("update_state_bookings", res.data.value.update_state_bookings === 1);
			sessionStorage.setItem("create_pos", res.data.value.create_pos === 1);
			sessionStorage.setItem("rename_pos", res.data.value.rename_pos === 1);
			sessionStorage.setItem("delete_pos", res.data.value.delete_pos === 1);
			sessionStorage.setItem("create_floor_pos", res.data.value.create_floor_pos === 1);
			sessionStorage.setItem("rename_floor_pos", res.data.value.rename_floor_pos === 1);
			sessionStorage.setItem("modify_floor_pos", res.data.value.modify_floor_pos === 1);
			sessionStorage.setItem("delete_floor_pos", res.data.value.delete_floor_pos === 1);
			sessionStorage.setItem("associate_client_pos", res.data.value.associate_client_pos === 1);
			sessionStorage.setItem("settings_access", res.data.value.settings_access === 1);
			sessionStorage.setItem("update_pos_settings", res.data.value.update_pos_settings === 1);
			sessionStorage.setItem("accept_booking", res.data.value.accept_booking === 1);
			sessionStorage.setItem("modify_user_rights", res.data.value.modify_user_rights === 1);
			sessionStorage.setItem("access_statistics", res.data.value.access_statistics === 1);

			// insertLog("User connection", "NULL", sessionStorage.getItem("id"), "Info");

			return resolve({
				state: "Ok",
				value: null
			});
		})
		.catch(res => {
			if (process.env.REACT_APP_DISPLAY_LOGS_0) console.log("[Get rights]: failed ->", res);

			return reject({
				state: "error",
				value: "An error occured while trying to connect you... Please retry or contact an administrator."
			})
		});
	});
}

export { getRights };