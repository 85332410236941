import Axios from 'axios';

const CUDBookings = (action, data) => {
	return new Promise((resolve, reject) => {
		let apiCall = null;
		const url = `${process.env.REACT_APP_API_ADRESS}/api/booking`;
		const config = {
			headers: {
				token: sessionStorage.getItem("id")
			}
		}

		if (action === "insert") apiCall = Axios.post(url, data, config);
		if (action === "update") apiCall = Axios.put(url, data, config);
		if (action === "delete") apiCall = Axios.delete(url, data, config);

		if (apiCall === null) return reject({
			state: "error",
			value: "An error occured. Please contact an administrator."
		});
		apiCall
		.then((res) => {
		// insertLog(`Booking (${action})`, getValues("id"), sessionStorage.getItem("id"), "Ok");
			return resolve({
				state: "OK", 
				value: null
			});
		})
		.catch(err => {
			if (err.response) {
				if (err.response.data.state === "I-POS") {
					return reject({
						state: "error",
						value: "The POS you selected is invalid."
					});
				}
				if (err.response.data.state === "C-POS") {
					return reject({
						state: "error",
						value: "The POS you selected is currently closed."
					});
				}
				if (err.response.data.state === "B-N-S") {
					return reject({
						state: "error",
						value: "The date time you choosed are not in the services of the POS."
					});
				}
				if (err.response.data.state === "I-DT") {
					return reject({
						state: "error",
						value: "The date you choose must be greater or equal to the todays one."
					});
				}
				if (err.response.data.state === "<=0") {
					return resolve({
						state: "error",
						value: "The coverts number must be greater or equal to 1."
					});
				}
				if (err.response.data.state === "T-C") {
					return reject({
						state: "warning",
						value: "The coverts limit is exceeded"
					});
				}
			}

			return reject({state: "error", value: "The operation you just did failed. Retry or contact the administrator."})
		});
	});
};

export { CUDBookings };