/*
TO DO : 
+ Reload select settings name
+ Make an option to delete POS
+ Period for the settings apply
+ Periodicity (each x days)
*/

import React, { useState, useEffect, useRef } from 'react';

import { useToast } from '../../hooks/useToast';
import { useFieldArray, useFormContext } from "react-hook-form";

import { get_settings } from '../../requests/settings/get_settings';
import { get_settingByID } from '../../requests/settings/get_settingByID';
import { post_posSettings } from '../../requests/settings/post_posSettings';
import { put_posSettings } from '../../requests/settings/put_posSettings';

// import insertLog from '../logs/insertLog';

import { Reorder } from "framer-motion";

import POSLine from "./assets/posLine";

const POSSettings = (props) => {
	const {
		handleSubmit,
		getValues,
		setValue,
		register,
		control,
		watch,
		formState: { errors }
	} = useFormContext();

	const toast = useToast();

	// Use State
	const [isLoading, setIsLoading] = useState(true);
	const [settingID, setSettingID] = useState(1);

	const [isDragging, setIsDragging] = useState(false);

	const [areNewSettings, setAreNewSettings] = useState(false);

	// Data
	const [timesReloaded, setTimesReloaded] = useState(0);
	const [data, setData] = useState({});
	const [settingsList, setSettingsList] = useState([]);

	// Display data
	useEffect(() => {
		const displayData = () => {
			if (Object.keys(data).length === 0) return;
			remove();

			const pos = data.pos !== null ? data.pos.split(',') : '';
			const open = data.open !== null ? data.open.split(',') : '';
			const services = data.services !== null ? data.services.split(',') : '';
			const coverts = data.coverts !== null ? data.coverts.split(',') : '';

			for (var i = 0; i < pos.length; i++) {
				append({
					name: pos[i],
					isOpen: (open.length > 0 ? open[i] === "true" : false),
					services: (services.length > 0 ? services[i].split('/') : []),
					coverts: (coverts.length > 0 ? coverts[i].split('/') : [])
				});
			}
			setPosList([...fields]);

			// Other values
			setValue("name", data.name);
			setValue("setting.groupSize", data.group_size);
			setValue("setting.periodActivation", data.period !== "always" ? data.period.split(':')[0] : "always");
			if (data.period.split(':')[0] === "period") {
				const date = data.period.split(':')[1].split('/');
				setValue("setting.period0", date[0]);
				setValue("setting.period1", date[1]);
			}
			if (data.period.split(':')[0] === "day") {
				const date = data.period.split(':')[1];
				setValue("setting.day", date);
			}
		}
		displayData();
	}, [data, timesReloaded]);


	const { fields, append, remove, replace } = useFieldArray({
		control,
		name: "pos"
	});

	const [posList, setPosList] = useState(fields);

	useEffect(() => setPosList(fields), [fields]);

	const getData = () => {
		if (settingID == null) {
			remove();

			append({
				name: '', 
				isOpen: false, 
				services: [], 
				coverts: []
			});
			setValue("name", '');
			setValue("setting.groupSize", 0);
			setValue("setting.periodActivation", '');
			setSettingID(null);
			return;
		}

		if (settingID == undefined) return;
		setAreNewSettings(false);

		setIsLoading(true);

		get_settingByID(settingID, true, false)
		.then((res) => {
			setIsLoading(false);

			remove(); // Delete all fields to recreate them

			setData(res.value[0]);
		})
		.catch(res => toast(res.state, res.value));
	}

	useEffect(() => getData(), [settingID]);

	useEffect(() => {
		// All settings
		setIsLoading(true);

		get_settings()
		.then((res) => {
			setIsLoading(false);
			setSettingsList(res.value)
		})
		.catch(res => toast(res.state, res.value));	

		// Settings of the selected name
		getData();
	}, [timesReloaded, settingID]);


	
	// CRUD:
	const insertData = (data) => {
		setIsLoading(true);

		post_posSettings(data)
		.then((res) => {
			setIsLoading(false);
			setSettingID(res.value.insertId);

			return toast(res.state, res.value);
		})
		.catch(res => toast(res.state, res.value));		
	}

	const updateData = (data) => {
		setIsLoading(true);

		put_posSettings(data, settingID)
		.then((res) => {
			setIsLoading(false);

			setTimesReloaded(timesReloaded + 1); // To reload
			getData();
			return toast(res.state, res.value)
		})
		.catch(res => toast(res.state, res.value));
	}

	const resetData = (event) => {
		event.preventDefault();

		setTimesReloaded(timesReloaded + 1);
	}

	const onSubmit = (data) => {
		if (areNewSettings) return insertData(data);
		setAreNewSettings(false);

		return updateData(data);
	}

	const periodActivation = watch('setting.periodActivation', false);

	const handleSettingChange = (e) => {
		if (e.target.value === "new") {
			setSettingID(null);
			return setAreNewSettings(true);
		}

		// Search id acording to name:
		let id = null;
		settingsList.forEach((obj) => {
			if (obj.name === e.target.value) id = obj.id;
		});

		if (id === null) return toast("error", "An error occured. Please try to relaod the page or contact an administrator.");
		setSettingID(id);

		setAreNewSettings(false);
	}

	const posSection = useRef(null);

	const canUserModifySettings = sessionStorage.getItem("update_pos_settings") === "true";

	return (
		<>
			<div className="flex-row-around settings-box__header">
				<h4>POS Settings</h4>
				<select id="setSettingNameDisplayed" onChange={handleSettingChange}>
					{settingsList.length !== 0 ? (
						settingsList.map((elm) => {
							return (
								<option key={elm.name} value={elm.name} selected={elm.id === settingID}>
									{elm.name.charAt(0).toUpperCase() + elm.name.slice(1).toLowerCase()}
								</option>
							);
						})
						) : ''
					}
					{ canUserModifySettings && <option value="new">New setting</option> }
				</select>
			</div>
			<form onSubmit={handleSubmit(onSubmit)}
				className="flex-col-around settings-box__content" 
				style={{filter: isLoading ? 'blur(6px) opacity(.6)' : ''}}
			>
				<div className="flex-col">
					<div className="flex-row" style={{gap: "10px", alignIntems: "center"}}>
						<p style={{marginLeft: "10px"}}>Setting name:</p>
						<input
							type="text"
							disabled={!canUserModifySettings}
							{...register("name", {required: true, maxLength: 45})}
							style={{borderRadius: "30px", paddingLeft: "5px", marginBlock: "auto"}}
						/>
					</div>
					{errors.name && errors.name.type === "required" && (
						<p className="form-error-message">The setting name field is required.</p>
					)}
					{errors.name && errors.name.type === "maxLength" && (
						<p className="form-error-message">The setting name field must be less the 45 characters.</p>
					)}
				</div>
				<div className="flex-col-between pos__section" ref={posSection}>
					<Reorder.Group
						values={fields}
						onReorder={replace}
						axis="y"
					>
						{fields.map((item, index) => ( 
							<POSLine 
								key={item.id}
								item={item}
								index={index}

								setAsideType={props.setAsideType}
								setTitle_POSAside={props.setTitle_POSAside}
								setIndex_POSAside={props.setIndex_POSAside}
								asideType={props.asideType}

								getValues={getValues}
								remove={remove}
								register={register}
								control={control}
								errors={errors}

								container={posSection}
							/>
						))}
					</Reorder.Group>
					{errors.pos  && (
						<p className="form-error-message">POS name field are required and must be less than 30 characters.</p>
					)}
					{
						canUserModifySettings &&
						<div className="flex-row">
							<button onClick={() => {append({name: '', isOpen: false, services: [], coverts: []})}}>Add POS</button>
						</div>
					}
				</div>

				<div className="flex-col" style={{margin: "0 10px"}}>
					<div className="flex-col">
						<div className="flex-row general__section">
							<p style={{margin: "0", lineHeight: "27px"}}>Group size :</p>
							<input 
								type="number"
								name="groupSize"
								min="1"
								pattern="[0-9]+"
								style={{margin: "0 20px"}}
								{...register("setting.groupSize", {disabled: !canUserModifySettings, required: true, min: 1, pattern: "[0-9]+"})}
								/>
						</div>
						{errors.setting?.groupSize && errors.setting?.groupSize.type === "required" && (
							<p className="form-error-message">The group size name field is required.</p>
						)}
						{(errors.setting?.groupSize && errors.setting?.groupSize === "min") && (
							<p className="form-error-message">The group size must be at least of 1.</p>
						)}
					</div>

					<div className="flex-col apply-ettings__section" style={{margin: "30px 0"}}>
						<label>
							<input 
								type="radio"
								value="always"
								{...register("setting.periodActivation", {required: true, disabled: !canUserModifySettings})}
								onClick={() => {
									setValue("setting.period0", '');
									setValue("setting.period1", '');
									setValue("setting.day", '');
								}}
							/>
							<span className="radio-custom"  style={{marginRight: "10px"}}></span>
							Always activated
						</label>
						<label style={{marginTop: "10px"}}>
							<input
								type="radio"
								value="period"
								{...register("setting.periodActivation", {required: true, disabled: !canUserModifySettings})}
								onClick={() => {
									setValue("setting.period0", '');
									setValue("setting.period1", '');
								}}
							/>
							<span className="radio-custom"  style={{marginRight: "10px"}}></span>
							Periodicaly activated<br/>
							<div className="flex-row" style={{lineHeight: "25px", marginLeft: "35px"}}>
								from:
								<input 
									type="date"
									style={{marginInline: "10px", height: "25px"}}
									{...register("setting.period0", {required: periodActivation === "period", disabled: !(canUserModifySettings && periodActivation === "period")})}
								/>
								to:
								<input 
									type="date"
									style={{marginInline: "10px", height: "25px"}}
									disabled={periodActivation !== "period" && "disabled"}
									{...register("setting.period1", {required: periodActivation === "period", disabled: !(canUserModifySettings && periodActivation === "period")})}
								/>
							</div>
							{
								(errors.setting?.period0 || errors.setting?.period1) && 
								(errors.setting?.period0?.type || errors.setting?.period1?.type) === "required" && 
								periodActivation === "period" && (
									<p className="form-error-message">Those date fields are required if you select "Periodicaly activated".</p>
								)
							}
						</label>
						<label style={{marginTop: "10px"}}>
							<input
								type="radio"
								value="day"
								{...register("setting.periodActivation", {required: true, disabled: !canUserModifySettings})}
								onClick={() => {
									setValue("setting.day", '');
								}}
							/>
							<span className="radio-custom" style={{marginRight: "10px"}}></span>
							Activated only one day
							<div className="flex-row" style={{lineHeight: "25px", marginLeft: "35px"}}>
								on the:
								<input 
									type="date"
									style={{marginInline: "10px", height: "25px"}}
									disabled={periodActivation !== "day" && "disabled"}
									{...register("setting.day", {required: periodActivation === "day", disabled: !(canUserModifySettings && periodActivation === "day")})}
								/>
							</div>
							{
								(errors.setting?.day || errors.setting?.day) && 
								(errors.setting?.day?.type || errors.setting?.day?.type) === "required" && 
								periodActivation === "day" && (
									<p className="form-error-message">Those date fields are required if you select "Activated only one day".</p>
								)
							}
						</label>
					</div>
				</div>
				<div className="flex-row" style={{width: "250px", marginBottom: "10px"}}>
					{ areNewSettings ? 
						<input type="submit" value="Create" style={{cursor: "pointer"}} disabled={!canUserModifySettings}/> :
						<input type="submit" value="Modify" style={{cursor: "pointer"}} disabled={!canUserModifySettings}/>
					}
					{ areNewSettings ? 
						<input type="reset" value="Abort changes" onClick={(e) => {setAreNewSettings(false)}} disabled={!canUserModifySettings}/> :
						<input type="reset" value="Abort changes" onClick={(e) => resetData(e)} disabled={!canUserModifySettings}/>
					}
				</div>
			</form>
			{ isLoading ?
				<div className="grid-center loader" style={{marginTop: "-265px", position: 'relative', zIndex: 1}}>
					<div>
						<img src="pictures/loadingIcon.svg" alt="loader"/>
						<h4>Loading settings...</h4>
					</div>
				</div> : null
			}
		</>
	)
}

export default POSSettings;