import React from 'react';
import { useState } from 'react';
import { useFormContext } from "react-hook-form";

import { put_bookingState } from '../../requests/bookings/put_bookingState';

import { useToast } from '../../hooks/useToast';

// import insertLog from '../logs/insertLog';

const Booking = (props) => {
	/*
	* Component useState constants
	*/
	const [state, setState] = useState(!props.state ? null : props.state);

	/*
	* Component variables
	*/
	const { setValue } = useFormContext();
	const setAsideType  	 = props.setAsideType;

	const bookingSelected 	 = props.bookingSelected;
	const setBookingSelected = props.setBookingSelected;

	const reloadBookings 	 = props.reloadBookings;
	const setReloadBookings  = props.setReloadBookings;

	const values = {
		id: 			props.bookingId,
		firstName: 		props.firstName,
		lastName: 		props.lastName,
		place: 			props.place,
		bookingDate: 	props.date,
		time: 			props.time,
		coverts: 		props.coverts,
		intExt: 		props.intExt,
		contact: 		props.contact,
		notes: 			(props.notes === '' || !props.notes) ? '---' : props.notes,
		state: 			props.state,
		isAccepted: 	props.isAccepted
	}

	const toast = useToast();

	/*
	* Component functions
	*/
	const fillBookingAside = () => {
		setValue("id", values.id);
		setValue("posName", values.place);
		setValue("firstName", values.firstName);
		setValue("lastName", values.lastName);
		setValue("date", values.bookingDate.split("T")[0]);
		setValue("time", values.time);
		setValue("oldDatetime", values.bookingDate.split("T")[0] + " " + values.time);
		setValue("coverts", values.coverts);
		setValue("oldCoverts", values.coverts);
		setValue("intExt", values.intExt);
		setValue("contact", values.contact);
		setValue("bookingNotes", values.notes === '---' ? '' : values.notes);
	}

	const changeState = (event, newState) => {
		event.stopPropagation();

		const data = {
			state: '',
			id: values.id,
			dateTime: values.bookingDate.split("T")[0] + " " + values.time,
			firstName: values.firstName,
			lastName: values.lastName
		};

		if (state === newState) {
			setState(null);
			data.state = '';
		}
		if (state !== newState) {
			setState(newState);
			data.state = newState;
		}

		// setIsLoading(true);
		put_bookingState(data)
		.then((res) => {
			// setIsLoading(false);
			setReloadBookings(reloadBookings + 1);
			// insertLog("Change booking state to : " + (res.data.value.length === 0 ? "none" : res.data.value), values.id, sessionStorage.getItem("id"), "Ok");

			setAsideType('classic');
			setBookingSelected(0);

			toast("OK", "Booking state updated successfuly.");
		})
		.catch(res => toast(res.state, res.value));
	}

	const canUserModifyBooking = sessionStorage.getItem("update_bookings") === "true";
	const canUserModifyBookingState = sessionStorage.getItem("update_state_bookings") === "true";

	return (
		<div 
			className={bookingSelected === values.id ? `bookingRow flex-row-around selected` : `bookingRow flex-row-around`} 
			onClick = {(event) => {
				event.stopPropagation();

				if (bookingSelected === values.id) {
					setBookingSelected(0);
					setAsideType("classic");
				} else {
					if (!canUserModifyBooking) return;
					setBookingSelected(values.id);
					fillBookingAside();
					setAsideType("MOD");
				}
			}}
			style={{
				cursor: canUserModifyBooking ? "pointer" : "not-allowed"
			}}
		>
			<div className="bookingRow_id">
				<h3>{values["id"]}</h3>
			</div>
			<div className="bookingRow_name">
				<p>
					{values["lastName"].toUpperCase()}
					<br/>
					{(values["firstName"].toLowerCase())[0].toUpperCase() + values["firstName"].slice(1)}
				</p>
			</div>
			<div className="bookingRow_place">
				<p>{values["place"]}</p>
			</div>
			<div className="bookingRow_dateTime">
				<p>
					{values["bookingDate"].split("T")[0].split('-').reverse().join('/')}
					<br/>
					{values["time"].split(":").slice(0, 2).join(":")}
				</p>
			</div>
			<div className="bookingRow_coverts">
				<p>{values["coverts"]}</p>
			</div>
			<div className="bookingRow_intExt">
				<p>{values["intExt"]}</p>
			</div>
			<div className="bookingRow_contact">
				<p>{values["contact"]}</p>
			</div>
			<div className="bookingRow_notes">
				<p>{values["notes"]}</p>
			</div>
			<div className="bookingRow_state">
			{
				values.isAccepted === "true" || values.isAccepted == 1
				? <>
					<button 
						style={values["state"] === 'arrived' ? {backgroundColor: "rgba(0, 176, 0, .2)"} : {}} 
						title="Set booking state to Arrived"
						onClick={(event) => changeState(event, 'arrived')}
						disabled={!canUserModifyBookingState ? "disabled" : ''}
					>Arrived</button>
					<button style={values["state"] === 'late' ? {backgroundColor: "rgba(255, 140, 0, .2)"} : {}} 
						title="Set booking state to Late"
						onClick={(event) => changeState(event, 'late')}
						disabled={!canUserModifyBookingState ? "disabled" : ''}
					>Late</button>
					<button style={values["state"] === 'noShow' ? {backgroundColor: "rgba(255, 0, 0, .2)"} : {}} 
						title="Set booking state to No Show"
						onClick={(event) => changeState(event, 'noShow')}
						disabled={!canUserModifyBookingState ? "disabled" : ''}
					>No show</button>
				</> 
				: <button 
					style={{
						backgroundColor: "rgba(150, 150, 150, .05)",
						color: "rgba(20, 20, 20, .3)",
						borderColor: "rgba(5, 5, 5, .2)",
						width: "fit-content",
						cursor: "not-allowed"
					}} 
					title="Waiting for a manager validation"
					>Waiting for validation</button>
			}
				
			</div>
		</div>
	);
}

export default Booking;
